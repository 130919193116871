<template>
  <v-container
    fluid
    style="display:flex; flex-direction: column; height: 100%;"
  >
    <Menu
      style="position:fixed; height: 50px; z-index: 1;margin-top:-12px; left: 0; right: 0; background: #DBC7FF"
      :title="'Back'"
    />
    <div
      class="mb-3 d-flex flex-row mx-15"
      style="margin-top: 40px; height: 100%;overflow-x: hidden; overflow-y:auto;"
    >
      <div
        style="width: 300px; display: flex; flex-direction: column; align-items: center; margin-top: 50px; padding-right: 40px;"
        class="hidden-xs-only"
      >
        <editable-avatar
          class="mb-5"
          :image="profile.image"
          :onImageSelected="onImageSelected"
          :shadow="false"
        />
        <label class="organization-name">
          {{ profile.organization }}
        </label>
        <label class="name-title">
          {{
            (profile.first_name ? profile.first_name : "") +
              " " +
              (profile.last_name ? profile.last_name : "")
          }}
        </label>
        <label class="email-title">
          {{ profile.email }}
        </label>
        <GmapMap :center="center" :zoom="15" class="map mt-4" v-if="center">
          <GmapMarker :position="center" />
        </GmapMap>
      </div>
      <div style="background-color: lightgrey; width: 1px" />
      <v-layout style="display: flex; flex-direction: column;">
        <label
          class="item-title"
          v-text="
            $route.params.id != 'add' ? 'Freezer Details' : 'Create a Freezer'
          "
        />
        <label class="email-title ml-10 mb-5"
          >Update and verify the freezer information</label
        >
        <div>
          <v-row class="px-6">
            <v-col md="6" cols="12">
              <v-text-field
                v-model="profile.organization"
                label="Organization Name"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                @change="onChange"
                class="mt-3"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-select
                v-model="profile.type"
                label="Type"
                :items="typeOptions"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                @change="onChange"
                class="mt-3"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="profile.first_name"
                label="Manager First Name"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                @change="onChange"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="profile.last_name"
                label="Manager Last Name"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                @change="onChange"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="profile.email"
                label="Email Address"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                @change="onChange"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="profile.phone"
                label="Phone"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                @change="onChange"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="profile.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :rules="[rules.min]"
                @click:append="showPassword = !showPassword"
                @change="onChange"
                label="Password"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                autocomplete="off"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="profile.confirm_password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :rules="[rules.min]"
                @click:append="showPassword = !showPassword"
                @change="onChange"
                label="Confirm Password"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                autocomplete="off"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                label="Address"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                v-model="profile.address"
                v-on:placechanged="getAddressData"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="profile.address2"
                label="Address 2"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                @change="onChange"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="profile.city"
                label="City"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                @change="onChange"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="profile.state"
                label="State"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                @change="onChange"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="profile.zip"
                label="Zip"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                @change="onChange"
              />
            </v-col>

            <v-col md="6" cols="12">
              <v-select
                v-model="profile.status"
                label="Status"
                :items="statusItems"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                @change="onChange"
              />
            </v-col>
          </v-row>
        </div>
        <div
          style="display:flex; justify-content:flex-end; flex-direction: row;"
        >
          <v-btn
            rounded
            width="200"
            color="primary"
            class="mb-3 mr-3"
            @click="onDeleteFreezer()"
            :loading="loading"
            v-if="profile._id"
          >
            Delete
          </v-btn>
          <v-btn
            rounded
            width="200"
            color="primary"
            class="mb-3 mr-3"
            @click="profile._id ? onSaveDetails() : onCreateFreezer()"
            :loading="loading"
          >
            {{ profile._id ? "Save" : "Create" }}
          </v-btn>
        </div>
      </v-layout>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
    <confirm-delete-dialog
      :deleteDialog="deleteFreezerDialog"
      :onClose="onCloseConfirmDelete"
      :onConfirm="onConfirmDelete"
    />
  </v-container>
</template>
<script>
import Menu from "../../components/core/Menu.vue";
import { mapActions } from "vuex";
import EditableAvatar from "../../components/core/EditableAvatar.vue";
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog.vue";

export default {
  components: {
    Menu,
    EditableAvatar,
    ConfirmDeleteDialog,
  },
  data() {
    return {
      errorMessage: null,
      snackbar: false,
      loading: false,
      showPassword: false,
      typeOptions: ["Freezer Manager", "Market"],
      serviceTypes: [],
      statusItems: ["Available", "Pending", "Disabled"],
      selectedImage: null,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      profile: { status: "Available" },
      deleteFreezerDialog: false,
      center: null,
    };
  },
  methods: {
    ...mapActions({
      getFreezer: "freezer/getFreezer",
      editFreezer: "freezer/editFreezer",
      deleteFreezer: "freezer/deleteFreezer",
      addFreezer: "freezer/addFreezer",
      getValues: "variable/getValues",
    }),
    onSaveDetails() {
      if (
        this.profile.password &&
        this.profile.password != this.profile.confirm_password
      ) {
        this.snackbar = true;
        this.errorMessage = "Password does not match";
        return;
      }
      this.loading = true;

      var formData = new FormData();
      Object.keys(this.profile).map((key) => {
        if (this.profile[key]) formData.append(key, this.profile[key]);
      });
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      this.editFreezer(formData)
        .then(() => {
          this.loading = false;
          this.$router.back();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          // if (error.response != undefined && error.response.status === 401) {
          //   this.tokenDialog = true;
          // } else {
          //   this.snackbar = true;
          //   this.errorMessage = error.response.data.message;
          // }
        });
    },
    onCreateFreezer() {
      if (!this.profile.password) {
        this.snackbar = true;
        this.errorMessage = "Password is required";
        return;
      }
      if (
        this.profile.password &&
        this.profile.password != this.profile.confirm_password
      ) {
        this.snackbar = true;
        this.errorMessage = "Password does not match";
        return;
      }
      this.loading = true;
      var formData = new FormData();
      Object.keys(this.profile).map((key) => {
        if (this.profile[key]) formData.append(key, this.profile[key]);
      });
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      this.addFreezer(formData)
        .then(() => {
          this.loading = false;
          this.$router.back();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          }
        });
    },
    onChange() {
      this.errorMessage = null;
    },
    getAddressData(addressData) {
      this.profile.address = addressData.name;
      this.profile.state = addressData.administrative_area_level_1;
      this.profile.city = addressData.locality;
      this.profile.zip = addressData.postal_code;
      this.profile.location =
        addressData.latitude + "," + addressData.longitude;
      this.$refs.address.update(this.profile.address);
      this.center = { lat: addressData.latitude, lng: addressData.longitude };
    },
    onImageSelected(image) {
      this.selectedImage = image;
    },
    onDeleteFreezer() {
      this.deleteFreezerDialog = true;
    },
    onCloseConfirmDelete() {
      this.deleteFreezerDialog = false;
    },
    onConfirmDelete() {
      this.deleteFreezerDialog = false;
      this.loading = true;
      this.deleteFreezer({ _id: this.profile._id })
        .then(() => {
          this.loading = false;
          this.$router.back();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          }
        });
    },
  },
  mounted() {
    if (this.$route.params.id != "add") {
      this.loading = true;
      this.getFreezer(this.$route.params.id)
        .then((data) => {
          this.loading = false;
          this.profile = data;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    }
  },
  watch: {
    "profile.location": function(newValue) {
      if (newValue.length == 2) {
        this.center = {
          lat: parseFloat(newValue[0]),
          lng: parseFloat(newValue[1]),
        };
      }
    },
  },
};
</script>
<style>
.item-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
}
.organization-name {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 20px;
  text-align: center;
}
.name-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 15px;
  text-align: center;
  margin-top: 10px;
}
.email-title {
  color: #6e3dc6;
  font-family: "Poppins-Medium";
  font-size: 15px;
}
.map {
  width: 200px;
  height: 150px;
}
.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}

.v-sheet::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-sheet::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-sheet::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-sheet::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
